import React from "react"
import Layout from "../layout"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../seo"
import PropertyDetailSection from '../propertyDetail/propertyDetailSection'
import ContactFormProductDetail from '../propertyDetail/contactFormProductDetail'
import { injectIntl } from "gatsby-plugin-intl";
import loadScript from "../loadScript"
import Img from "gatsby-image"
 
import {formatPrice} from './formatPrices';

import propertiesData from "../../../build_data/properties.json";
import zonesData from "../../../build_data/zones.json";

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

const PropertyDetail = ({ data, intl, pageContext}) => ({

    loadLanguage(){
        const language = intl.locale; 
        var href= window.$("#print-url-href").attr("href");
        var printUrl = href + "&lang=" + language;
        window.$("#print-url-href").attr("href", printUrl);
    },

    loadLanguageScripts() {    
      var self = this;
      loadScript(document.location.origin + '/theme/output/bundle.js')
      .then((script) => {
          self.loadLanguage();
      })
      .catch((err) => {
          console.error(err.message);
      });
    },

    componentDidMount(){ 
      if (!window.$) {
          this.loadLanguageScripts();
      }
      else{
          this.loadLanguage();
      }
    },

    getLanguageDescription() {
      var property = propertiesData.filter(p => p.id === pageContext.id)[0];
      const language = intl.locale;
      return property.propertiesdescription ? property.propertiesdescription[language] : '';
    },

    getProductTitle(){
      var property = propertiesData.filter(p => p.id === pageContext.id)[0];
      return intl.formatMessage({ id:'type-'+ property.type}) + 
                        (property.complex.length > 0 ? " - " : "") + 
                        (property.complex.length > 0 ? property.complex : "") +                    
                        (property.Zone != "Select_Zone" ? " - " : "") +
                        (property.Zone != "Select_Zone" ? intl.formatMessage({ id:'zones.'+ property.Zone}) : "") + 
                        "  " + 
                        (intl.formatMessage({ id:'municipality.'+ property.municipality}));
    }, 

    render(){
      var property = propertiesData.filter(p => p.id === pageContext.id)[0];
      var zone = zonesData.filter(x => x.Name === property.Zone)[0];
      const language = intl.locale;
      // build SEO data
      var mainImage = 0;
      if(property.Main_Image > 0){
        mainImage =  property.Main_Image - 1;
      }
      mainImage = property.images[mainImage].url;
      
      return (
        <StaticQuery
          query={graphql`
            query {
              pageTopBg: file(relativePath: { eq: "page-top-bg.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              printShare: file(relativePath: { eq: "print-share.png" }) {
                  childImageSharp {
                      fixed(width: 45) {
                          ...GatsbyImageSharpFixed
                      }
                  }
              },
            }
          `}
          render={data => (
            <Layout>
              
              <SEO 
                title={intl.formatMessage({ id:'Property-Detail'})} 
                languageDescription={this.getLanguageDescription()} 
                propertyTitle={this.getProductTitle()}
                mainImage={mainImage} 
              />
          
              <div className="site-breadcrumb propertyDetailPage">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 single-list-page">
                      <PropertyDetailSection property={property} language={language} zone={zone} intl={intl} languageDescription={this.getLanguageDescription()} propertyTitle={this.getProductTitle()}></PropertyDetailSection>
                    </div>
                    <div className="col-lg-4 col-md-7 sidebar">
                      <div className="detail-price">
                            <span className="price-btn">{formatPrice(property.price)}</span>
                      </div>
                      <ContactFormProductDetail property={property}  intl={intl}></ContactFormProductDetail>
                      <div className="print-property">
                        <a
                          href={"https://print-visaverde.azurewebsites.net/api/PrintProperty?propertyId=" + property.id + "&date=" +  property.updated_at}
                          target="_blank"
                          id="print-url-href"
                        >
                          <i className="fa fa-print"></i>{intl.formatMessage({ id:'Property-printable-version'})}                          
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <Fab
                  mainButtonStyles={{ backgroundColor: '#0d6529', borderRadius: 48, fontSize: '2em', width: '2.5em', height: '2.5em' }}
                  icon={<Img className="detailIcon" style={{ width: '1.8em', height: '1.8em' }} fixed={data.printShare.childImageSharp.fixed} />}
                  event="click"
                  >
                  <Action
                    text="Print"
                    style={{ backgroundColor: '#3e9044', borderRadius: 48, fontSize: '1.8em', width: '58px', height: '58px' }}
                    onClick={() => 
                      window.open(
                        `https://print-visaverde.azurewebsites.net/api/PrintProperty?propertyId=${property.id}&date=${property.updated_at}&lang=${language}`,
                        '_blank',
                      )
                    }
                  >
                    <i className="fa fa-print"></i>
                  </Action>
                  
                  <FacebookShareButton
                    url={`https://visaverde.com/${language}/property/${property.id}`}
                    quote="Visaverde"
                    hashtag="#Visaverde"
                    className="floating-share-button"
                    title="Facebook"
                  >
                    <FacebookIcon size={58} round />
                  </FacebookShareButton>
                  
                  <WhatsappShareButton
                    url={`https://visaverde.com/${language}/property/${property.id}`}
                    className="floating-share-button"
                    title="Visaverde"
                  >
                    <WhatsappIcon size={58} round />
                  </WhatsappShareButton>
                </Fab>
              </div>
            </Layout>
          )}
        />
      )
    }
})

export default injectIntl(PropertyDetail)
